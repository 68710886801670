.full-height-container {
  height: 100vh;
}

.join-us-banner-container {
    height: calc(93vh - 175px);
    background-size: 100%;
    margin-right: 50px;
    margin-left: 50px;
    background: url('../images/join-us-photo.png') no-repeat center;
    background-size: 120%; 
    transition: background-size 0.3s ease;
    transform: translateY(175px); /* Translate down by 175px */
  }
  
  .contact-container {
    margin: 50px 15vw 150px 15vw;
  }

  .contact-container p a {
    color: #6e9fc1
  }

  .small-icon {
    width: 50px;
  }

  @media only screen and (max-width: 450px) {
    .contacts {
      margin-bottom: 50px;
    }
    .join-us-banner-container {
        background-size: 200%;
        height: 50vh;
        height: calc(50vh - 120px);
        transform: translateY(120px);
      }
      .contact-person {
        flex: 1;
        padding: 20px;
        text-align: center;
      }
      .location-info-col {
        flex: 1;
        margin: 0px;
        margin-bottom: 50px;
      }     
      .full-height-container {
        height: 50vh;
      }
      .contact-container {
        margin: 50px 25px 50px 25px;
      }
      .location-info-col {
        padding-left: 25px;
      }
  }