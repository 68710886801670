body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2, h3, p, nav ul li a, b {
  font-family: "IBM Plex Sans", sans-serif;
  color: #212427;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 6px;
  color: #212427;
}

h1 {
  font-size: calc(24px + 1.5vw);
}

h2 {
  font-size: calc(20px + 1vw);
}

h3 {
  font-size: calc(16px + 0.4vw);
}

p {
  font-size: calc(14px + 0.4vw);
}

.content-container {
  margin-top: 175px;
  text-align: center;
}

.container {
  margin-top: 175px;
}

.center {
  text-align: center;
}

.border-top {
  border-top: 1px solid black;
}

.sm-bottom-margin {
  margin-bottom: 50px;
}

.balanced-p {
  text-wrap: balance;
}

/* NAVIGATION */
.nav-bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  width: 100%;
  z-index: 1001;
  transition: padding 0.5s ease-in-out, background-color 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0);
}

.fslightbox-open .nav-bar {
  display: none;
}

.fslightbox-open .go-up {
  display: none;
}

.fslightbox-open .photos-container {
  z-index: 0;
}

.nav-fade-in {
  background-color: rgba(225, 225, 225, 0.2);
  backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  padding-top: 2px;
  padding-bottom: 2px;
  transition: padding s ease-in-out, background-color 0.5s ease-in-out;
  box-shadow: 0 2px 60px rgba(0, 0, 0, 0.1);
}

.nav-fade-in a {
  color: #333;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 50px;
  padding-right: 7vw;
  margin: 0;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  text-decoration: none;
  color: #b7b7b7;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  transition: color 0.5s; 
  font-size: calc(13px + 0.4vw);
}

nav ul li a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 0%;
  height: 2px;
  background: #8D181B;
  margin: auto;
  transition: all 0.5s;
}

nav ul li a.active {
  color: #8D181B;
}

nav ul li a.active:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: #8D181B;
  margin: auto;
}

nav ul li a:hover {
  color: #8D181B;
}

nav ul li a:hover:after {
  width: 100%;
}

#name-logo {
  padding-left: 6vw;
  width: 100%;
  max-width: 450px;
  height: auto;
}

.col.logo {
  flex: 0 0 auto;
  max-width: 100px;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #b7b7b7;
  margin-left: auto;
  margin-right: 50px;
}

@media (max-width: 1500px) {
  .navbar-menu {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5000%;
    padding: 10px;
    background-color: rgb(34, 34, 36);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding-top: 100px;
    transition: transform 0.5s ease-in-out;
    transform: translateX(100%);
    text-align: center;
    display: flex;
    z-index: 1;
  }

  .nav-fade-in a {
    color: #b7b7b7;
  }
  
  .navbar-title {
    color: #b7b7b7;
  }

  .mobile-menu-open {
    display: flex;
    transform: translateX(0%);
  }

  .white-icon svg{
    color: #b7b7b7;
  }

  .navbar-item {
    padding: 15px;
  }

  .mobile-menu-icon {
    position: absolute;
    cursor: pointer;
    color: #b7b7b7;
    display: block;
    z-index: 9999;
    right: 0;
  }
  
  #name-logo {
    width: 90%;
  }
  
}

@media (max-width: 450px) {
  .logo a {
    margin-left: 2rem;
  }
  #name-logo {
    margin-top: 10px;
    width: 70%;
  }
  .mobile-menu-icon {
    margin-right: 40px;
  }
}

/* SCROLL UP */
.go-up {
  position: fixed;
  bottom: 50px;
  right: 0px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.go-up.show {
  opacity: 0.7;
}

.go-up button {
  background: none;
  border: none;
  cursor: pointer;
}

.go-up img {
  border: none;
  width: 30px;
  height: 30px;
}

.go-up img:hover {
  filter: brightness(70%) sepia(100%) hue-rotate(0deg) saturate(500%) brightness(100%);
}

/* FOOTER */
footer {
  background-color: rgb(34, 34, 36);
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

footer p, footer b{
  color: #b7b7b7;
}

.som-logo {
  width: 15%;
  min-width: 100px;
  padding-top: 30px;
}

.temp {
  height: 150vh;
}

@media (max-width: 450px) {
  .som-logo {
    width: 50%;
    min-width: 150px;
    max-width: 250px;
    padding-top: 30px;
  }

  .container {
    margin-top: 145px !important;
  }

  .photos-container {
    margin: 15vh 5vw 50px 5vw !important;
  }

  .content-container {
    margin-top: 145px;
  }

  .featured-publications-container {
    margin: 145px 8vw 10px 8vw !important;
  }
}

@media (max-width: 1500px) {
  .som-logo {
    width: 30%;
    min-width: 150px;
    max-width: 250px;
    padding-top: 30px;
  }
}