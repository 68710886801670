.team-page {
  margin: 50px 15vw 150px 15vw;
}

.bill-robinson {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  min-height: auto;
  border-top: 1px solid black;
  padding: 50px 0px 50px 0px;
}

.image-column {
  flex: 1;
}

.image-column img {
  width: 100%;
  min-width: 230px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 50%;
}

.info-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.info-column h3 {
  margin-bottom: 10px;
}

.info-column p {
  margin: 0;
  font-weight: normal;
  font-style: italic;
  margin-bottom: 5px;
  
}

@media only screen and (max-width: 800px) {
  .bill-robinson {
    margin-left: 0px;
    height: auto;
  }

  .image-column,
  .info-column {
    flex: 1 0 100%; 
    align-items: center;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1400px) {
.info-column {
    align-items: center;
  }
}

.team-page-people {
    border-top: 1px solid black;
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-image {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .card-content {
    margin-top: 10px;
  }
  
  .card-content h2 {
    margin: 0;
  }
  
  .card-content p {
    margin: 5px 0;
  }

  .button-container {
    margin-bottom: 20px;
  }
  
  .button-container button {
    padding: 10px 20px;
    margin: 0 10px;
    width: 200px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid #8D181B;
    background-color: white;
    color: black;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }

  @media only screen and (max-width: 600px) {
    .button-container button {
      width: 180px;
    }
  }

  @media only screen and (max-width: 400px) {
    .button-container button {
      width: fit-content;
    }
  }
  
  .button-container button.selected-button {
    background-color: #8D181B;
    color: white;
  }

  .alumni-list ul {
    column-count: 3;
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
  }

  .all-alumni p {
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  @media only screen and (max-width: 600px) {
    .alumni-list ul {
        column-count: 1;
      }
  }

  .alumni-list h2 {
    padding-top: 40px;
  }

  .all-alumni h2 {
    padding-top: 40px;
  }

  h2.no-bottom-margin {
    margin-bottom: 0;
  }

  h3.no-top-margin {
    margin-top: 0;
    font-weight: normal;
  }

  .all-alumni ul{
    padding: 0;
    margin-bottom: 40px;
  }

  .all-alumni {
    margin: 50px 15vw 100px 15vw;
  }

  .alumni-section {
    border-top: 1px solid black;
  }

  @media only screen and (max-width: 1400px) {
    .image-column {
      margin: 0;
    }
  }