  .photos-container {
    margin: 20vh 5vw 150px 5vw;
    height: auto;
    opacity: 0;
    animation: fadeIn 3s ease-in-out forwards; /* Apply fadeIn animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  figcaption {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0; /* Ensure it starts from the left */
    z-index: 1000;
    width: 100%; /* Adjust width to match the image width */
    background: linear-gradient(rgba(25, 27, 38, 0), rgb(25, 27, 38));
    padding: 8px; /* Reduce padding to prevent extending beyond the image */
    font-size: 0.85rem;
    text-align: center;
    color: rgb(255, 255, 255);
    box-sizing: border-box; /* Ensure padding is included in the width */
  }