.home-container {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: black;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://d2jx2rerrg6sh3.cloudfront.net/images/news/ImageForNews_749051_16849074323925838.jpg') no-repeat top right fixed;
  background-size: 100%; 
  transition: background-size 0.5s ease;
}

@media (max-width: 1200px) {
  .home-container {
    background-size: 150% !important;
  }
}

@media (max-width: 1024px) {
  .home-container {
    background-size: 350% !important;
  }
}

.home-description {
  width: 60%;
  margin-left: 7vw;
  position: relative; /* Add position relative for child positioning */
}

.home-description h1,
.home-description p {
  color: white;
}

.home-description h1 {
  margin-bottom: 10px;
}

.home-description p {
  margin-bottom: 20px;
  font-size: calc(14px + 0.4vw);
}

.styled-button {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: calc(10px + 0.4vw); /* Inherit font size from paragraphs */
}

.styled-button-black {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: calc(10px + 0.4vw); /* Inherit font size from paragraphs */
}

.styled-button {
  background-color: transparent;
  border: 2px solid white;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  height: 8vh;
  min-height: 65px;
  width: 15vw;
  min-width: fit-content;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  margin-top: 20px;
  padding: 20px;
}

.styled-button:disabled {
  pointer-events: none;
}

.styled-button:hover {
  color: #fff;
  border: 2px #8D181B;
  background-color: #8D181B;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.styled-button:active {
  box-shadow: none;
  transform: translateY(0);
}


.styled-button-black {
    background-color: transparent;
    border: 2px solid rgb(0, 0, 0);
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    height: 8vh;
    min-height: 65px;
    width: 15vw;
    min-width: fit-content;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    margin-top: 20px;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 20px;
  }
  
  .styled-button-black:disabled {
    pointer-events: none;
  }
  
  .styled-button-black:hover {
    color: #fff;
    border: 2px #8D181B;
    background-color: #8D181B;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .styled-button-black:active {
    box-shadow: none;
    transform: translateY(0);
  }

  @media only screen and (max-width: 900px) {
    .styled-button, .styled-button-black {
        width: 30vw;
      }
  }


.home-content {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  align-items: center;
  margin: 0 10vw 0 10vw;
}

.home-pub {
    border-top: 2px solid black;
    padding-top: 50px;
    margin-top: 50px;
  }

.team-photo {
    margin-bottom: 20px;
    width: 70%;
}

.home-body {
    margin: 50px 0 50px 0;
}

@media (max-width: 450px) {
  .styled-button, .styled-button-black {
    width: 50vw;
    height: auto;
  }

  .home-content {
    margin: 0;
  }
  
  .team-photo {
    margin-bottom: 20px;
    width: 85%;
}
  .home-description {
    width: 85%;
    margin: 0;
  }
  .styled-button {
    font-size: calc(12px + 0.4vw); /* Inherit font size from paragraphs */
  }
  
  .styled-button-black {
    font-size: calc(12px + 0.4vw); /* Inherit font size from paragraphs */
  }

  .home-content {
    padding-bottom: 20px;
  }
}

@media (max-width: 1500px) {

}
