.full-height-container {
  height: 100vh;
}

.banner-container {
  height: calc(93vh - 175px);
  background-size: 100%;
  margin-right: 50px;
  margin-left: 50px;
  background: url('https://wallpapercave.com/wp/wp2017235.jpg') no-repeat center;
  background-size: 170%; 
  transition: background-size 0.3s ease;
  transform: translateY(175px); /* Translate down by 175px */
}
  
  .contact-container {
    margin: 50px 15vw 150px 15vw;
  }

  .location, .contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .location-col {
    flex: 2;
    margin: 50px;
  }

  .location-info-col {
    flex: 1;
    margin: 50px;
  }

  .borders {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .contact-person {
    flex: 1;
    padding: 0px 50px 50px 50px;
    text-align: center;
  }

  @media only screen and (max-width: 450px) {
    .contacts {
      margin-bottom: 50px;
    }
    .banner-container {
        background-size: 400%;
        height: 50vh;
        height: calc(50vh - 120px);
        transform: translateY(120px);
      }
      .contact-person {
        flex: 1;
        padding: 20px;
        text-align: center;
      }
      .location-info-col {
        flex: 1;
        margin: 0px;
        margin-bottom: 50px;
      }     
      .full-height-container {
        height: 50vh;
      }
      .contact-container {
        margin: 50px 25px 50px 25px;
      }
      .location-info-col {
        padding-left: 25px;
      }
  }